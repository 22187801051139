/********************************
******ACCOUNT SIDEBAR************
********************************/

$(document).on("click", "#support-open", function() {
    var chatbotContainer = $('.mwai-chatbot-container');
    var openButton = chatbotContainer.find('.mwai-open-button');
    var closeButton = chatbotContainer.find('.mwai-close-button');
    var chatbotVisible = chatbotContainer.find('.mwai-chatbot').hasClass('mwai-transition-visible');
    if (openButton.length) {
      if (chatbotVisible && closeButton.length) {
        closeButton.trigger('click');
        setTimeout(function() {
          openButton.trigger('click');
        }, 500);
      } else {
        openButton.trigger('click');
      }
    }
});

/********************************
******ACCOUNT QUOTES ******
********************************/

function get_quotes() {
    // ajax call to et quotes
     jQuery('.open-quotes ul').html('');
     jQuery('.closed-quotes ul').html('');
    jQuery.ajax({
        url: ajax_object.ajaxurl,
        type: "post",
        data: { action: "get_customer_quotes" },
        success: function(data) {
            if (data != 0) {
            var quotes = data;
            for (var i = 0; i < quotes.length; i++) {

                    var quote = quotes[i];
                    var quote_date = quotes[i].lead_received_date;
                    var status = quotes[i].status;

                    var date = new Date(quote_date);
                    var month = ("0" + (date.getMonth() + 1)).slice(-2);
                    var day = ("0" + date.getDate()).slice(-2);
                    var year = date.getFullYear();
                    // just show 24 as the year
                    year = year.toString().slice(-2);
                    var formatted_date = day + '/' + month + '/' + year;
                    var industry = quote.industry;
                    var optinurl = quote.optinurl;

                    if (status != 'Sold') {
                        var quote_html = '<li><div class="date"><p>' + formatted_date + '</p></div><div class="quote-name"><p><i class="fa-solid fa-clipboard-list" aria-hidden="true"></i> ' + industry + '</p></div><div class="cta"><a target="_blank" href="' + optinurl + '">Finish</a></div></li>';
                        jQuery('.open-quotes ul').append(quote_html);
                    } else {
                        var quote_html = '<li><div class="date"><p>' + formatted_date + '</p></div><div class="quote-name"><p><i class="fa-solid fa-clipboard-list" aria-hidden="true"></i> ' + industry + '</p></div><div class="cta"><a target="_blank" href="' + optinurl + '">Leave Review</a></div></li>';
                        jQuery('.closed-quotes ul').append(quote_html);
                    }
                
            }
            }
        }
    });
}


// Get Quotes on page load
jQuery(document).ready(function(){
    if (jQuery('.quotes-tracker').length > 0) {
    get_quotes();
    }
});

/********************************
******ACCOUNT QUESTIONNAIRE TEMPLATE******
********************************/

function update_points_total() {
    jQuery.ajax({
        url: ajax_object.ajaxurl,
        type: "post",
        data: { action: "get_user_data_on_login" },
        success: function(data) {
            if (data.error) {
                console.error('Error:', data.error);
            } else {
                // get customer_points
                var customer_points = data.customer_points;
                var level_name = data.level_name;
                var next_level_name = data.next_level_name;
                var points_to_next_level = data.points_to_next_level;
                var points_to_next_level_percentage = data.points_percentage;
                jQuery('#points-total').text(customer_points);
                jQuery('#current-level').text(level_name);
                jQuery('.content-top p:eq(2)').text(' Points to eneter ' + next_level_name);
                jQuery('#remaining-points').text(points_to_next_level);
                jQuery('.points-progress-inner').css('width', points_to_next_level_percentage + '%');
            }
        },
        error: function(jqXHR, textStatus, errorThrown) {
            //console.error('AJAX Error:', textStatus, errorThrown);
        }
    });
}

// Save Answers
jQuery(document).on("click", "#save-questionnaire", function(){
    var questionaireBlock = jQuery(this).closest('.questionnaire-block-bottom');
    var $questionnaire = jQuery(this).closest('.questionnaire-block-bottom'),
        answers = [];
    var allAnswered = true;

    $questionnaire.find('.question-block').each(function() {
        if(jQuery(this).attr("data-conditional") == "true"){
            var isAnswered = true;
        } else {
            var field = jQuery(this).data('field');
            var value = '';
            var isAnswered = false;
            // Multiple Choice Values
            var multipleChoice = jQuery(this).find('.multiple-choice').find('.active');
            if (multipleChoice.length) {
                if(multipleChoice.closest('question-block').attr("data-conditional") == "true") {
                    isAnswered = true;
                } else {
                    value = jQuery.trim(multipleChoice.text()),
                    valueLength = value.length;
                    if(valueLength > 0) {
                        isAnswered = true;
                    }
                }
            }
            // Select Values
            var select = jQuery(this).find('select');
            if (select.length) {
                value = select.val(),
                    valueLength = value.length;
                if(valueLength > 0) {
                    isAnswered = true;
                }
            }
            // Input Values
            var input = jQuery(this).find('input');
            if (input.length) {
                value = input.val(),
                valueLength = value.length;
                if(valueLength > 0) {
                    isAnswered = true;
                }
            }
    
            if (!isAnswered) {
                allAnswered = false;
            }
            
            answers.push({ [field]: value });
        }
    });

    if (allAnswered) {
        var industryQuestionaire = jQuery(questionaireBlock).parent().data("industry");
        // if industryQuestionaire has spaces, replace with underscore
        industryQuestionaire = industryQuestionaire.replace(/\s/g, '_');
        answers.push({ [industryQuestionaire]: 1 });
        if (jQuery(questionaireBlock).prev().find('.icon').hasClass("active")) {

        } else {
            var industryPoints = jQuery(questionaireBlock).parent().data("questionairepoints");
            answers.push({ customer_points : industryPoints });
        }
        // convert to json
        answers = JSON.stringify(answers);
        // AJAX
        jQuery.ajax({
            url: ajax_object.ajaxurl,
            type: "post",
            data: {action: "questionnaire_submit", questionnaireAnswers: answers},
            success: function (data) {
                jQuery(questionaireBlock).next().removeClass("active");
                jQuery(questionaireBlock).parent().removeClass("active");
                jQuery(questionaireBlock).parent().removeAttr("style");
                jQuery(questionaireBlock).prev().find('.icon').removeClass("error").addClass("active");
                fireNotyf('Successfully submitted questionnaire!', 'success');
                update_points_total();
                location.reload();
            }, error : function (response) {
                jQuery(questionaireBlock).prev().find('.icon').addClass("error");
            }
        });
    } else {
        var industryQuestionaire = jQuery(questionaireBlock).parent().data("industry");
        answers.push({ [industryQuestionaire]: 0 });
        if (jQuery(questionaireBlock).prev().find('.icon').hasClass("active")) {

        } else {
            var industryPoints = jQuery(questionaireBlock).parent().data("questionairepoints");
            answers.push({ customer_points : industryPoints });
            jQuery(questionaireBlock).prev().find('.icon').addClass("error");
            fireNotyf('Please submit answers for all highlighted questions.', 'error');
        }
    }
}); 
 
jQuery(document).on("click", ".multiple-choice ul li", function(){
    var questionaireBlock = jQuery(this).closest('.questionnaire-block');
    if(jQuery(this).parent().find(".active")){
        jQuery(this).parent().find(".active").removeClass("active");
        jQuery(this).addClass("active");
    } else {
        jQuery(this).addClass("active");
    }
    if(jQuery(this).attr("data-conditional-answer") == "true"){
        questionaireBlock.find('[data-conditional="1"]').attr("data-conditional", "true");
        questionaireBlock.find('[data-conditional="true"]').each(function(){
            jQuery(this).find("input").val("");
            jQuery(this).find("li.active").removeClass("active");
            jQuery(this).find("select").val("");
        });
    } else {
        questionaireBlock.find('[data-conditional="true"]').attr("data-conditional", "1");
    }
});

jQuery(window).on("load", function(){
    jQuery(".multiple-choice ul li.active").each(function(){
        var questionaireBlock = jQuery(this).closest('.questionnaire-block');
        if(jQuery(this).attr("data-conditional-answer") == "true"){
            questionaireBlock.find('[data-conditional="1"]').attr("data-conditional", "true");
            questionaireBlock.find('[data-conditional="true"]').each(function(){
                jQuery(this).find("input").val("");
                jQuery(this).find("li.active").removeClass("active");
                jQuery(this).find("select").val("");
            });
        }
    }); 
});

/********************************
******ACCOUNT GETTING STARTED TEMPLATE******
********************************/

// Start
jQuery(document).on("click", "#introduction-start", function(){
    jQuery(".introduction-wrapper").removeClass("active");
    jQuery(".question-block-wrapper[data-count='1']").addClass("active");
});
// Question Count
var questionCount = 0;
jQuery(".question-block-wrapper").each(function(){
    questionCount++;
    jQuery(this).attr("data-count", questionCount);
});
// Questionnaire
jQuery(document).on("click", "#question-next", function(){
    var $questionnaire = jQuery(this).closest('.question-block-inner');
    $questionnaire.find('.answers-wrapper').each(function() {
        // Multiple Choice Values
        var multipleChoice = $questionnaire.find('.multiple-choice');
        if (multipleChoice.length) {
            value = multipleChoice.find('.active').text();
            if(value == ""){
                fireNotyf('Please select an answer before continuing!', 'error');
                return;
            }
        }
        // Select Values
        var select = $questionnaire.find('select');
        if (select.length) {
            value = select.val();
            if(value == ""){
                fireNotyf('Please select an answer before continuing!', 'error');
                return;
            }
        }
        // Input Values
        var input = $questionnaire.find('input');
        if (input.length) {
            value = input.val();
            if(value == ""){
                fireNotyf('Please select an answer before continuing!', 'error');
                return;
            }
        }
        jQuery(".question-block-wrapper.active").removeClass("active").next().addClass("active");
    });
});
// Submit 
jQuery(document).on("click", "#question-last", function(){
    var answers = [];
    // var answers = [];
    var i = 1;
    jQuery('.question-block-wrapper').each(function() {
        if(i >= jQuery('.question-block-wrapper').length){
            jQuery('.question-block-wrapper.active').each(function() {
                var field = jQuery(this).data('field'),
                value = '';
                // Multiple Choice Values
                var multipleChoice = jQuery(this).find('.multiple-choice');
                if (multipleChoice.length) {
                    value = multipleChoice.find('.active').text();
                    if(value == ""){
                        fireNotyf('Please select an answer before continuing!', 'error');
                        return;
                    } else {
                        answers.push({ [field]: value });
                        completeInitialQuestionnaire(answers);
                    }
                }
                // Select Values
                var select = jQuery(this).find('select');
                if (select.length) {
                    value = select.val();
                    if(value == ""){
                        fireNotyf('Please select an answer before continuing!', 'error');
                        return;
                    } else {
                        answers.push({ [field]: value });
                        completeInitialQuestionnaire(answers);
                    }
                }
                // Input Values
                var input = jQuery(this).find('input');
                if (input.length) {
                    value = input.val();
                    if(value == ""){
                        fireNotyf('Please select an answer before continuing!', 'error');
                        return;
                    } else {
                        answers.push({ [field]: value });
                        completeInitialQuestionnaire(answers);
                    }
                }
            });
            
        } else {
            var field = jQuery(this).data('field'),
                value = '';
            // Multiple Choice Values
            var multipleChoice = jQuery(this).find('.multiple-choice');
            if (multipleChoice.length) {
                value = multipleChoice.find('.active').text() || "";
                if(value == ""){
                    fireNotyf('Please select an answer before continuing!', 'error');
                    return;
                }
            }
            // Select Values
            var select = jQuery(this).find('select');
            if (select.length) {
                value = select.val();
                if(value == ""){
                    fireNotyf('Please select an answer before continuing!', 'error'); 
                    return;
                }
            }
            // Input Values
            var input = jQuery(this).find('input');
            if (input.length) {
                value = input.val();
                if(value == ""){
                    fireNotyf('Please select an answer before continuing!', 'error');
                    return;
                }
            }
            answers.push({ [field]: value });
            i++;
        }
    });
 });

 function completeInitialQuestionnaire(answers){
    answers = answers;
    answers.push({ "customer_points" : 1000 });
    answers.push({ "initial_questionaire" : 1 });
    // convert to json
    answers = JSON.stringify(answers);
    jQuery(".completed-section-wrapper").addClass("active");
    jQuery(".question-block-wrapper.active").removeClass("active");
    // AJAX
    jQuery.ajax({
        url: ajax_object.ajaxurl,
        type: "post",
        data: {action: "questionnaire_submit", questionnaireAnswers: answers},
        success: function (data) {
            update_points_total();
        }, error : function (response) {

        }
    });
 }

 /********************************
******ACCOUNT PROFILE TEMPLATE******
********************************/

/* Edit Sections */

jQuery(document).on("click", ".profile-settings ul li", function(){
    if(jQuery(this).attr("id") != "support-open") {
        var seletedSection = jQuery(this).attr("data-id");
        jQuery(`.account-profile-inner-top, .account-profile-inner-bottom`).hide();
        jQuery(`.profile-edit-section.active`).removeClass("active").hide();
        jQuery(`.profile-edit-section[data-id=${seletedSection}]`).addClass("active").fadeIn(500);
    }
});

jQuery(document).on("click", ".profile-edit-section #edit-disabled", function(){
    jQuery(this).hide();
    jQuery(this).closest(".disabled-input").find("input:disabled").removeAttr("disabled").show();
    jQuery(this).closest(".disabled-input").find("select:disabled").removeAttr("disabled").show();
});

/* Add New Address/Vehicle Sections */

jQuery(document).on("click", ".profile-edit-section #add-address, .profile-edit-section #add-vehicle", function(){
    var sectionID = jQuery(this).attr("id");
    jQuery(`.profile-edit-section.active`).removeClass("active").hide();
    jQuery(`.profile-edit-section.${sectionID}`).addClass("active").fadeIn(500);
});

/* Save & Cancel */

jQuery(document).on("click", ".profile-edit-section-bottom #cancel", function(){
    location.reload();
});

jQuery(document).on("click", ".profile-edit-section-bottom #save", function(){
    console.log('click save');
    // Check to see if this is for a new address / vehicle
    if(jQuery(this).closest(".profile-edit-section").hasClass("add-address") || jQuery(this).closest(".profile-edit-section").hasClass("add-vehicle")) {
        console.log('kofi');
        var answers = [],
            saveStatus = true;
        jQuery(".profile-edit-section.active input").each(function(){
            var inputValue = jQuery(this).val(),
                inputID = jQuery(this).attr("id");
            // Detect if any fields missing
            if(!saveStatus){
                return;
            }
            // Detect and change checkbox input
            if(jQuery(this).attr("type") == "checkbox"){
                if (jQuery(this).is(':checked')) {
                    inputValue = "yes";
                } else {
                    inputValue = "no";
                }
            }
            // Detect optional fields and if required fields are empty
            if(inputID != "address-line-2" && inputID != "address-region"){
                if(inputValue != ""){
                    answers.push({ [inputID]: inputValue });
                    saveStatus = true;
                } else {
                    saveStatus = false;
                }
            } else {
                saveStatus = true;
                answers.push({ [inputID]: inputValue });
            }
        });
        jQuery(".profile-edit-section.active .multiple-choice").each(function(){
            var inputValue = jQuery(this).find('.active').text(),
                inputID = jQuery(this).attr("data-id");
            if(inputValue != ""){
                answers.push({ [inputID]: inputValue });
            } else {
                saveStatus = false;
            }
        });
        // If all required fields are filled then run AJAX
        if(saveStatus){
            // AJAX HERE **I would also reload the page on success to reset all DOM elements back to normal**
            jQuery.ajax({
                url: ajax_object.ajaxurl,
                type: "post",
                data: {action: "questionnaire_submit", questionnaireAnswers: answers},
                success: function (data) {
                    console.log(data);
                    update_points_total();
                    location.reload();
                }, error : function (response) {
                    
                }
            });
        } else {
            fireNotyf('Please fill all required fields before saving.', 'error');
        }
    } else {
        var answers = [],
            saveStatus = true;
        jQuery(".profile-edit-section.active input, .profile-edit-section.active select").each(function(){
            var inputValue = jQuery(this).val(),
                inputID = jQuery(this).attr("id");
            // Detect if any fields missing
            if(!saveStatus){
                return;
            }
            if(inputID != "address-line-2" && inputID != "address-region"){
                if(inputValue != ""){
                    answers.push({ [inputID]: inputValue });
                } else {
                    saveStatus = false;
                }
            } else {
                saveStatus = true;
                answers.push({ [inputID]: inputValue });
            }
        });
        // If all required fields are filled then run AJAX
        if(saveStatus){
            console.log('hello2');
            answers = JSON.stringify(answers);
            // AJAX HERE **I would also reload the page on success to reset all DOM elements back to normal**
            jQuery.ajax({
                url: ajax_object.ajaxurl,
                type: "post",
                data: {action: "personal_details_submit", questionnaireAnswers: answers},
                success: function (data) {
                    //update_points_total();
                    location.reload();
                }, error : function (response) {
                   
                }
            });
        } else {
            fireNotyf('Please fill all required fields before saving.', 'error');
        }
    }
});


/********************************
******ACCOUNT FORM TEMPLATE******
********************************/

jQuery(".tml-wrapper input, .account-pass-content-wrapper input").on("change", function(){
    if(jQuery(this).val().length > 0) {
        jQuery(this).addClass("active");
    } else {
        jQuery(this).removeClass("active");
    }
});

var percentage = 0; 
function passwordStrength(n, m) { 
    if (n < 4) { 
        percentage = 0; 
        jQuery(".password-strength-bar").css("background", "#ed2d15"); 
        jQuery("#min-chars").removeClass("active");
    } else if (n < 6) { 
        percentage = 20; 
        jQuery(".password-strength-bar").css("background", "#fc6c19"); 
        jQuery("#min-chars").removeClass("active");
    } else if (n < 8) { 
        percentage = 40; 
        jQuery(".password-strength-bar").css("background", "#fcc719"); 
        jQuery("#min-chars").removeClass("active");
    } else { 
        percentage = 50; 
        jQuery("#min-chars").addClass("active");
    }  
    // Lowercase Words only 
    if ((m.match(/[a-z]/) != null)) { 
        percentage += 10; 
    }
    // Uppercase Words only 
    if ((m.match(/[A-Z]/) != null)) { 
        percentage += 10; 
        jQuery("#upper-chars").addClass("active");
    } else {
        jQuery("#upper-chars").removeClass("active");
    }
    // Digits only 
    if ((m.match(/0|1|2|3|4|5|6|7|8|9/) != null)) { 
        percentage += 10; 
        jQuery("#number-chars").addClass("active");
    } else {
        jQuery("#number-chars").removeClass("active");
    }
    // Special characters 
    if ((m.match(/\W/) != null) && (m.match(/\D/) != null)) { 
        percentage += 10; 
        jQuery("#special-chars").addClass("active");
    } else {
        jQuery("#special-chars").removeClass("active");
    }
    // Ensure high %
    if(percentage >= 90){
        percentage = 100;
    }
    if(percentage >= 80){
        jQuery(".password-strength-bar").css("background", "#9aed34"); 
    }
    jQuery(".password-strength-bar").css("width", percentage + "%"); 
} 

jQuery(document).on("keyup", "#confirm-password", function(){
    var m = jQuery(this).val(),
        n = m.length; 
    var c = jQuery('.tml-wrapper #password').val();
    if(m == c) {
        passwordStrength(n, m); 
    }
    if(m.length < 1) {
        jQuery(".password-strength-bar").removeAttr("style");
    } 
});   

jQuery(document).on("click", ".account-pass-content-wrapper #submit-pass", function(){
    jQuery(".account-pass-content-wrapper form input").each(function(){
        var valueLength = jQuery(this).val().length;
        if(valueLength > 0){
            jQuery(".account-pass-content-wrapper #submit-pass, .account-pass-content-wrapper .account-progress .step2").addClass("active");
        } else {
            jQuery(".account-pass-content-wrapper #submit-pass, .account-pass-content-wrapper .account-progress .step2").removeClass("active");
        }
    });
});

/********************************
******COPY REFERRAL FUNCTIONS******
********************************/

function copyToClipboard(text) {
    var textArea = document.createElement("textarea");
    textArea.value = text;
    document.body.appendChild(textArea);       
    textArea.select();
    try {
        var successful = document.execCommand('copy');
        fireNotyf('Successfully copied to clipboard!', 'success');
        jQuery('.copy-code-inner').addClass('highlight');
        setTimeout(function(){
            jQuery('.copy-code-inner').removeClass('highlight');
        }, 500);
    } catch (err) {
        
    }    
    document.body.removeChild(textArea);
 }

jQuery(document).on("click", ".copy-code-inner #copy-code", function(){
    var clipboardText = "";
    clipboardText = $('.copy-code-inner #referral-url').val(); 
    copyToClipboard(clipboardText);
});

/********************************
******ACCOUNT DASHBOARD TEMPLATE******
********************************/
jQuery('.account-content-wrapper .points-container-inner .content.slider').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    arrows: true,
    infinite: false
});
var currentIndex = $('.content.slider .level-block.current').attr("data-index"),
    currentIndex = currentIndex - 1;
if(currentIndex >= 0) {
    $('.content.slider').slick('slickGoTo', currentIndex);
}
jQuery('.account-content-wrapper .points-container-inner .content.slider').addClass("active"); 

/********************************
******ACCOUNT REWARDS REALM TEMPLATE******
********************************/
jQuery('.account-content-wrapper .available-rewards-inner .content.available-rewards-slider').slick({
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 6000,
    dots: true,
    arrows: false,
    infinite: false,
    responsive: [
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
}); 

jQuery('.account-content-wrapper .featured-rewards-inner .content.rewards-slider').slick({
    slidesToShow: 5,
    slidesToScroll: 3,
    autoplay: true,
    autoplaySpeed: 6000,
    dots: false,
    arrows: false,
    infinite: true,
    responsive: [
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1
          }
        }
      ]
});

jQuery('.account-content-wrapper .featured-rewards-inner .content.rewards-slider, .account-content-wrapper .available-rewards-inner .content.available-rewards-slider').addClass("active"); 


// Load Reward Product
jQuery(document).on('click', '.featured-rewards-wrapper .featured-reward', function(){
    jQuery('.rewards-realm-wrapper').addClass('product-viewer');
    var brand = jQuery(this).attr("data-brand");
    
    // change brand dropdown
    jQuery('#brand-filter').val(brand).change();

});

jQuery(document).on('click', '.rewards-list .reward-block', function(){
    jQuery('.rewards-realm-wrapper').addClass('product-viewer');
    var rewardID = jQuery(this).attr("data-id");
    // FETCH DATA FROM PRODUCT ID

    jQuery.ajax({
        url: ajax_object.ajaxurl,
        type: "post",
        data: {action: "get_reward_data", rewardID: rewardID},
        success: function (data) {
            // access the json data
            var productImg = data.pictureUrl;
            var productTitle = data.name;
            var productDesc = data.summary;
            var productCost = data.price;
            var domain = data.metadata_domains;

            // Update Product Viewer Data
            jQuery('.product-viewer-header-image img').attr("src", productImg);
            jQuery('.product-viewer-header-title').text(productTitle);
            jQuery('.product-viewer-body.description .description-text p').text(productDesc);
            // if productcose is 0 then show this is on us
            if(productCost == 0){
                var productCost = "Claim free reward!";
                jQuery('.product-viewer-body.redeem #redeem-cost span').text(productCost);
            } else {
                jQuery('.product-viewer-body.redeem #redeem-cost span').text(productCost + " Points");
            }
            jQuery('.product-viewer-body .redeem-text p').text("Go to " + domain + " and enter the code to redeem your reward.");
            // Show Product Viewer
            jQuery('.rewards-realm-wrapper .account-content-wrapper').hide();
            jQuery('.rewards-realm-wrapper .product-viewer-wrapper').addClass('active');  

            // scroll to top
            jQuery('html, body').animate({
                scrollTop: jQuery('.product-viewer-inner').offset().top
            }, 500);

        }, error : function (response) {
            
        }
    });

});

// Load Gift Card Product
jQuery(document).on('click', '.available-rewards-wrapper .available-reward', function(){
    // if has class locked then don't allow to be clicked
    if(jQuery(this).hasClass("locked")){
        fireNotyf('This reward is locked. Please complete the required actions to unlock.', 'error');
    } else {
    jQuery('.rewards-realm-wrapper').addClass('product-viewer');
    var rewardID = jQuery(this).attr("data-id");

    jQuery(this).toggleClass("active").siblings().removeClass("active");
    
    // FETCH DATA FROM PRODUCT ID
    jQuery.ajax({
        url: ajax_object.ajaxurl,
        type: "post",
        data: {action: "get_benefit_by_id", rewardID: rewardID},
        success: function (response) {
            var giftdata = JSON.parse(response);
            // Access the data directly since it's not wrapped in another object
            var productImg = giftdata.data.pictureUrl;
            var productTitle = giftdata.data.name;
            var productDesc = giftdata.data.description;
            var productCost = giftdata.data.price;


            // Update Product Viewer Data
            jQuery('.product-viewer-header-image img').attr("src", productImg);
            jQuery('.product-viewer-header-title').text(productTitle);
            jQuery('.product-viewer-body .redeem-text p').text("Once you redeem this reward, you will receive an email with your gift card code.");
            jQuery('.product-viewer-body .redeem-button button').text("Exchange for Gift Card");
            // CHANGE THE ID OF THE BUTTON
            jQuery('.product-viewer-body .redeem-button button').attr("id", "redeem-gift-card");

            // if productCost is 0 then show "Claim free reward!"
            if (productCost == 0) {
                var productCost = "Claim free reward!";
                jQuery('.product-viewer-body.redeem #redeem-cost span').text(productCost);
            } else {
                jQuery('.product-viewer-body.redeem #redeem-cost span').text(productCost + " Points");
            }

            // Show Product Viewer
            jQuery('.rewards-realm-wrapper .account-content-wrapper').hide();
            jQuery('.rewards-realm-wrapper .product-viewer-wrapper').addClass('active');  

            // Scroll to top
            jQuery('html, body').animate({
                scrollTop: jQuery('.rewards-realm-wrapper').offset().top
            }, 500);

        }, 
        error: function (response) {
            console.error("Error fetching reward data:", response);
        }
    });
    }
});

// ON CLICK OF #redeem-gift-card AJAX CALL TO REDEEM GIFT CARD
jQuery(document).on('click', '#redeem-gift-card', function(){
    var rewardID = jQuery('.available-rewards-wrapper .available-reward.active').attr("data-id");
    jQuery.ajax({
        url: ajax_object.ajaxurl,
        type: "post",
        data: {action: "purchase_gift_card", rewardID: rewardID},
        success: function (response) {
            jQuery(this).parent().hide();
            jQuery('.redeem-reward-loader').show();
            setTimeout(function(){
                jQuery('.redeem-reward-loader').hide();
                // close the product viewer
                jQuery('.product-viewer-wrapper').removeClass('active');
                jQuery('.account-content-wrapper').show();
                // reload the page
                location.reload();
                
            }, 250);
        },
        error: function (response) {
            console.error("Error fetching reward data:", response);
        }

    });
});

// Go Back
jQuery(document).on('click', '.product-viewer-back > p', function(){
    location.reload();
});

// Open/Close reward categories
jQuery(document).on('click', '.rewards-list .header > h2', function(){
    if(jQuery(this).closest('.rewards-list').hasClass('active')){
        var openHeight = jQuery(this).closest('.rewards-list').attr('o-height'),
        closedHeight = jQuery(this).closest('.rewards-list').attr('c-height');
        jQuery(this).closest('.rewards-list').attr('style', `max-height: ${openHeight}px`);
        jQuery(this).closest('.rewards-list').removeClass('active');
    } else {
        var openHeight = jQuery(this).closest('.rewards-list').outerHeight(),
        closedHeight = jQuery(this).closest('.header').outerHeight();
        jQuery(this).closest('.rewards-list').attr('style', `max-height: ${closedHeight}px`).attr('c-height', `${closedHeight}`).attr('o-height', `${openHeight}`);
        jQuery(this).closest('.rewards-list').addClass('active');
    }
});

jQuery(window).on("load resize", function(){
    jQuery('.rewards-list-inner .rewards-list').each(function(){
        var maxHeight = jQuery(this).outerHeight();
        jQuery(this).closest('.rewards-list').attr('style', `max-height: ${maxHeight}px`);
    });
});

// Start Redeem
jQuery(document).on('click', '.product-viewer-body.description #description-redeem', function(){
    jQuery('.product-viewer-body.description').hide();
    jQuery('.product-viewer-loader').show();
    setTimeout(function(){
        jQuery('.product-viewer-loader').hide();
        jQuery('.product-viewer-body.redeem').addClass('active');
    }, 250);
});

// Redeem Button
jQuery(document).on('click', '.product-viewer-body.redeem #redeem-reward', function(){
    // WLL API CALL FOR GETTING COUPON CODE
    // On success:
    jQuery(this).parent().hide();
    jQuery('.redeem-reward-loader').show();
    setTimeout(function(){
        jQuery('.redeem-reward-loader').hide();
        jQuery('.product-viewer-body.redeem .redeem-code').addClass('active');
    }, 250);
});

// Copy Redeem Code
jQuery(document).on('click', '.product-viewer-body.redeem .redeem-code-input-inner', function(){
    var clipboardText = "";
    clipboardText = $(this).find('#redeem-code').val(); 
    jQuery('#redeem-code').select(); 
    copyToClipboard(clipboardText);
});

// Generate QR Code
jQuery(document).on('click', '.product-viewer-body.redeem .redeem-code-input-wrapper #redeem-code-qr', function(){
    jQuery('.product-viewer-body.redeem').hide();
    jQuery('.product-viewer-body.qr-code').addClass('active');
});

// Load All Rewards

function loadAllRewards(){
    if(jQuery('.rewards-list-inner').length > 0){
        // AJAX
        jQuery.ajax({
            url: ajax_object.ajaxurl,
            type: "post",
            data: { action: "get_rewards_by_category" },
            success: function(data) {
                currentCategory = '';
                for (var i = 0; i < data.length; i++) {
                    var productImg = data[i].pictureUrl,
                    productTitle = data[i].name,
                    productID = data[i].deal_id,
                    productBrand = data[i].brand,
                    productCategory = data[i].category;
                    if(productCategory !== currentCategory){
                        // if (currentCategory !== '') {
                        //     jQuery('.rewards-list-inner').append('</div>'); // Close the previous rewards-list div
                        // }
                        jQuery('.rewards-list-main').append(`
                            <div class="rewards-list" data-category="${productCategory}">
                                <div class="header">
                                    <h2>${productCategory}</h2>
                                </div>
                        `);
                        currentCategory = productCategory;
                    }
                    jQuery(`.rewards-list-main .rewards-list[data-category='${currentCategory}']`).append(`
                        <div class="reward-block" data-id="${productID}" data-brand="${productBrand}">
                            <div class="reward-block-image">
                                <img src="${productImg}">
                            </div>
                            <div class="reward-block-title">
                                <p>${productTitle}</p>
                            </div>
                        </div>
                    `);
                }
                jQuery(".rewards-list-loader").removeClass("active");
            }
        });
    }
}

jQuery(window).on("load", function(){
    loadAllRewards();
});


// Load More Rewards
var offsetNum = 10;
jQuery(document).on("click", "#load-more-rewards", function(){
    jQuery('#load-more-rewards').addClass('loading');
    if(jQuery('.rewards-list-inner').length > 0){
        offsetNum+=10;
        // AJAX
        jQuery.ajax({
            url: ajax_object.ajaxurl,
            type: "post",
            data: { action: "get_more_rewards", offset: offsetNum },
            success: function(data) {
                currentCategory = '';
                for (var i = 0; i < data.length; i++) {
                    var productImg = data[i].pictureUrl,
                    productTitle = data[i].name,
                    productID = data[i].deal_id,
                    productBrand = data[i].brand,
                    productCategory = data[i].category;
                    if(productCategory !== currentCategory){
                        // if (currentCategory !== '') {
                        //     jQuery('.rewards-list-inner').append('</div>'); // Close the previous rewards-list div
                        // }
                        jQuery('.rewards-list-main').append(`
                            <div class="rewards-list" data-category="${productCategory}">
                                <div class="header">
                                    <h2>${productCategory}</h2>
                                </div>
                        `);
                        currentCategory = productCategory;
                    }
                    jQuery(`.rewards-list-main .rewards-list[data-category='${currentCategory}']`).append(`
                        <div class="reward-block" data-id="${productID}" data-brand="${productBrand}">
                            <div class="reward-block-image">
                                <img src="${productImg}">
                            </div>
                            <div class="reward-block-title">
                                <p>${productTitle}</p>
                            </div>
                        </div>
                    `);
                }
                jQuery('#load-more-rewards').removeClass('loading');
            }
        });
    }
});

jQuery(document).ready(function() {
    function filterByCategory(){
        var selectedCategory = $('#category-filter').val().toLowerCase();
        if(jQuery('.rewards-list-inner').length > 0){
            // AJAX
            jQuery.ajax({
                url: ajax_object.ajaxurl,
                type: "post",
                data: { action: "filter_rewards_category", selectedCategory: selectedCategory }, 
                success: function(data) {
                    jQuery('.rewards-list-main').hide().empty();
                    jQuery(".rewards-list-loader").addClass("active");
                    if(selectedCategory != 'all'){
                        jQuery("#search-filter").val('');
                        jQuery("#brand-filter").val('all');
                        setTimeout(function(){
                            jQuery(".rewards-list-loader").removeClass("active");
                            jQuery('.rewards-list-main').show();
                        }, 250);
                        jQuery('.rewards-list-main').append(`
                            <div class="rewards-list" data-category="${selectedCategory}">
                                <div class="header">
                                    <h2>${selectedCategory}</h2>
                                </div>
                        `);
                        for (var i = 0; i < data.length; i++) {
                            var productImg = data[i].pictureUrl,
                            productTitle = data[i].name,
                            productID = data[i].deal_id,
                            productBrand = data[i].brand,
                            productCategory = data[i].category.toLowerCase();
                            jQuery(`.rewards-list-main .rewards-list[data-category='${productCategory}']`).append(`
                                <div class="reward-block" data-id="${productID}" data-brand="${productBrand}">
                                    <div class="reward-block-image">
                                        <img src="${productImg}">
                                    </div>
                                    <div class="reward-block-title">
                                        <p>${productTitle}</p>
                                    </div>
                                </div>
                            `);
                        }
                    } else {
                        jQuery('.rewards-list-main').empty();
                        setTimeout(function(){
                            jQuery(".rewards-list-loader").removeClass("active");
                            jQuery('.rewards-list-main').show();
                        }, 250);
                        loadAllRewards();
                    }
                }
            });
        }
    }

    function filterByBrand(){
        var selectedBrand = $('#brand-filter').val().toLowerCase();
        if(jQuery('.rewards-list-inner').length > 0){
            // AJAX
            jQuery.ajax({
                url: ajax_object.ajaxurl,
                type: "post",
                data: { action: "filter_rewards_brand", selectedBrand: selectedBrand }, 
                success: function(data) {
                    jQuery('.rewards-list-main').hide().empty();
                    jQuery(".rewards-list-loader").addClass("active");
                    if(selectedBrand != 'all'){
                        jQuery("#search-filter").val('');
                        jQuery("#category-filter").val('all');
                        setTimeout(function(){
                            jQuery(".rewards-list-loader").removeClass("active");
                            jQuery('.rewards-list-main').show();
                        }, 250);
                        jQuery('.rewards-list-main').append(`
                            <div class="rewards-list" data-brand="${selectedBrand}">
                                <div class="header">
                                    <h2>${selectedBrand}</h2>
                                </div>
                        `);
                        for (var i = 0; i < data.length; i++) {
                            var productImg = data[i].pictureUrl,
                            productTitle = data[i].name,
                            productID = data[i].deal_id,
                            productBrand = data[i].brand.toLowerCase();
                            jQuery(`.rewards-list-main .rewards-list[data-brand='${productBrand}']`).append(`
                                <div class="reward-block" data-id="${productID}" data-brand="${productBrand}">
                                    <div class="reward-block-image">
                                        <img src="${productImg}">
                                    </div>
                                    <div class="reward-block-title">
                                        <p>${productTitle}</p>
                                    </div>
                                </div>
                            `);
                        }
                    } else {
                        jQuery('.rewards-list-main').empty();
                        setTimeout(function(){
                            jQuery(".rewards-list-loader").removeClass("active");
                            jQuery('.rewards-list-main').show();
                        }, 250);
                        loadAllRewards();
                    }
                }
            });
        }
    }

    function filterBySearch(){
        var selectedSearch = $('#search-filter').val().toLowerCase();
        if(jQuery('.rewards-list-inner').length > 0){
            // AJAX
            jQuery.ajax({
                url: ajax_object.ajaxurl,
                type: "post",
                data: { action: "filter_rewards_search", selectedSearch: selectedSearch }, 
                success: function(data) {
                    jQuery("#brand-filter, #category-filter").val('all');
                    if(selectedSearch != ''){
                        jQuery('.rewards-list-main').hide().empty();
                        jQuery(".rewards-list-loader").addClass("active");
                        setTimeout(function(){
                            jQuery(".rewards-list-loader").removeClass("active");
                            jQuery('.rewards-list-main').show();
                        }, 250);
                        jQuery('.rewards-list-main').append(`
                            <div class="rewards-list" data-search="search">
                                <div class="header">
                                    <h2>Search Results</h2>
                                </div>
                        `);
                        for (var i = 0; i < data.length; i++) {
                            var productImg = data[i].pictureUrl,
                            productTitle = data[i].name,
                            productID = data[i].deal_id,
                            productBrand = data[i].brand.toLowerCase();
                            jQuery(`.rewards-list-main .rewards-list[data-search='search']`).append(`
                                <div class="reward-block" data-id="${productID}" data-brand="${productBrand}">
                                    <div class="reward-block-image">
                                        <img src="${productImg}">
                                    </div>
                                    <div class="reward-block-title">
                                        <p>${productTitle}</p>
                                    </div>
                                </div>
                            `);
                        }
                    } else {
                        jQuery(".rewards-list-loader").addClass("active");
                        jQuery('.rewards-list-main').hide().empty();
                        loadAllRewards();
                        setTimeout(function(){
                            jQuery(".rewards-list-loader").removeClass("active");
                            jQuery('.rewards-list-main').show();
                        }, 250);
                    }
                }
            });
        }
    }

    $('#category-filter').on('change', filterByCategory);
    $('#brand-filter').on('change', filterByBrand);
    $('#search-filter').on('input', filterBySearch);
});